import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../MyLayout';
import s from './Error404.module.scss';
import Image from '../Image';
import Error404Jpg from './floo-powder-starlabsasset.jpg';
import SegmentPage from '../SegmentPage';
import Hero from '../Hero';
import { DISCOVER_FILMS, HOME_URL } from '../../constants/routes';
import Button from '../Button';
import ArticleHeroImageSource from '../../pages/Article/components/ArticleHeroImageSource';

const propTypes = {
  statusCode: PropTypes.number,
};

const imageSet = [
  {
    src: Error404Jpg,
  },
];

const error = {
  pageName: 'Error',
  analytics: 'Error',
  analyticsKey: 'ErrorPage',
  hero: {
    error404: {
      title: 'Oh dear. Are you lost?',
      subtitle: 'Come back in a little while or return to the homepage.',
      eyebrow: (
        <Image
          imageSet={imageSet}
          alt="Harry Potter tries Floo Powder at The Burrow, while the Weasleys tentatively look on."
        />
      ),
    },
    error500: {
      title: 'Something went wrong!',
      subtitle: 'Come back in a little while or return to the homepage.',
      eyebrow: (
        <Image
          imageSet={imageSet}
          alt="Harry Potter tries Floo Powder at The Burrow, while the Weasleys tentatively look on."
        />
      ),
    },
  },
  buttonLabel: 'Back to Wizarding World',
};

const imageSourceProps = {
  url: DISCOVER_FILMS,
  title: 'Discover the films',
  description: 'Harry Potter to Fantastic Beasts',
};

const Error404 = ({ statusCode = 404 }) => {
  let heroProps;
  if (statusCode === 404) {
    heroProps = error.hero.error404;
  } else {
    heroProps = error.hero.error500;
  }

  return (
    <Layout>
      <div className={s.root}>
        <>
          <Hero className={s.rootInner} {...heroProps} tall>
            <SegmentPage key={error.analyticsKey} pageName={error.analytics} />
            <div className={s.buttonRow}>
              <a href={HOME_URL} className={s.link} title={error.buttonLabel}>
                <Button
                  className={s.button}
                  label={error.buttonLabel}
                  level="primary2"
                  theme="white"
                  tabIndex="-1"
                />
              </a>
            </div>
          </Hero>
          <div className={s.imageSource}>
            <ArticleHeroImageSource {...imageSourceProps} pageName={error.analytics} />
          </div>
        </>
      </div>
    </Layout>
  );
};

Error404.propTypes = propTypes;
export default Error404;
